<template>
  <div class="tracking-earnings">
    <div class="tracker-content">
      <div class="annual-earnings-summary">
        <span class="summary-title">Yıllık Toplam Kazanç</span>
        <span class="summary-summary">{{ formatNumberWithComma(yearlyTotalIncome) }} TL</span>
      </div>
      <div class="filters-container">
        <div class="filters-item" v-for="(item, i) in filterTypes" :key="i">
          <div class="filter-details">
            <div class="filter-point" :style="{ background: item.pointColor }" />
            <div class="filter-type">{{ item.text }}</div>
            <div class="filter-toggle">
              <label class="switch">
                <input type="checkbox" :checked="item.isActive" @change="toggleFilter(item)" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div class="filter-value">{{ formatNumberWithComma(item.value) }} TL</div>
        </div>
      </div>
    </div>

    <BrandBarChart
      :chartData="barChartData"
      :chartOptions="barChartOptions"
      :hasBackgroundBarPlugin="true"
      :hasSelectedBar="true"
      @selectedBarItem="selectedBarItem"
    />
    <div class="chart-divider" />
    <div class="chart-date-filter">
      <div
        class="chart-date-item"
        v-for="(item, index) in dateFilterItems"
        @click="toggleDateFilter(item)"
        :key="index"
      >
        <img
          :src="
            item.isActive
              ? require('@/assets/icons/green-check.svg')
              : require('@/assets/icons/empty-ellipse.svg')
          "
          alt="icon"
        />
        <div
          :style="{ color: item.isPrev ? CHART_COLORS.YELLOW : CHART_COLORS.ORANGE }"
          class="chart-date-text"
        >
          {{ item.date, }}
        </div>
      </div>
    </div>
    <div class="tracker-content">
      <div class="montly-earnings-summary">
        <span class="summary-title"> {{ selectedMonthFullName }} Ayı Toplam Kazanç</span>
        <span class="summary-summary">{{ formatNumberWithComma(monthlyTotalIncome) }} TL</span>
      </div>
      <div class="montly-earning-details">
        <div v-for="(month, index) in montlyDetails" :key="index" class="detail-item">
          <div class="detail-content">
            <div style="display: flex;align-items: center;">
              <div class="filter-point" :style="{ background: month.pointColor }" />
              <div class="detail-type">{{ month.text }}</div>
            </div>
            <div class="detail-value">
              {{ getMonthDetailValue(month) }}
            </div>
          </div>
          <div class="divider" v-if="index < montlyDetails.length - 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onBeforeMount, computed } from 'vue';
import BrandBarChart from '@/components/brand/BrandChart/BrandBarChart.vue';
import { BusinessDashboard } from '@/services/Api/index';
import { formatNumberWithComma } from '@/utils/publicHelper';

const POS_CHANNEL_TYPES = {
  GT: 1,
  KACC: 2,
  LAMP: 3,
};

const FILTER_TYPES = {
  SALE: 'saleTotalIncome',
  CASH_INVESTMENT: 'cashInvestmentTotalIncome',
  TRADE: 'tradeTotalIncome',
  YK: 'ykTotalIncome',
  MARATHON: 'marathonTotalIncome',
  EORDER: 'eorderTotalIncome',
};

const INCOME_TYPE_LABELS = {
  SALE: 'Satış',
  CASH_INVESTMENT: 'Nakit Yatırım',
  TRADE: 'Aylık Puanlama',
  YK: 'Yap-Kazan',
  MARATHON: 'Yarışmalar',
  EORDER: 'E-Sipariş',
};

const CHART_COLORS = {
  ORANGE: '#F16F1B',
  GREEN: '#28B873',
  PURPLE: '#A258B1',
  YELLOW: '#FFC107',
  BLUE: '#4A51BB',
  RED: '#D02D2C',
  GRAY: '#C2C5C3',
  DARK_GRAY: '#C2C2C2',
  TEXT_GRAY: '#79838E',
  TEXT_DARK: '#23303D',
};

export default {
  components: { BrandBarChart },
  setup() {
    const selectedItemIndex = ref(null);
    const yearlyTotalIncome = ref('');
    const activeYear = ref(0);
    const fullMonthYear = ref('');
    const monthlyIncomes = ref({});
    const posChannelType = ref(null);
    const monthlyIncomeDetail = ref({});
    const selectedMonthLabel = ref();
    const selectedMonthFullName = ref('');

    const filterTypes = ref([
      {
        text: INCOME_TYPE_LABELS.SALE,
        isActive: true,
        value: '',
        pointColor: CHART_COLORS.ORANGE,
        type: FILTER_TYPES.SALE,
      },
      {
        text: INCOME_TYPE_LABELS.CASH_INVESTMENT,
        isActive: true,
        value: '',
        pointColor: CHART_COLORS.GREEN,
        type: FILTER_TYPES.CASH_INVESTMENT,
      },
      {
        text: INCOME_TYPE_LABELS.TRADE,
        isActive: true,
        value: '',
        pointColor: CHART_COLORS.PURPLE,
        type: FILTER_TYPES.TRADE,
      },
      {
        text: INCOME_TYPE_LABELS.YK,
        isActive: true,
        value: '',
        pointColor: CHART_COLORS.YELLOW,
        type: FILTER_TYPES.YK,
      },
      {
        text: INCOME_TYPE_LABELS.MARATHON,
        isActive: true,
        value: '',
        pointColor: CHART_COLORS.BLUE,
        type: FILTER_TYPES.MARATHON,
      },
      {
        text: INCOME_TYPE_LABELS.EORDER,
        isActive: true,
        value: '',
        pointColor: CHART_COLORS.RED,
        type: FILTER_TYPES.EORDER,
      },
    ]);

    const montlyDetails = ref([
      { text: INCOME_TYPE_LABELS.SALE, value: '', pointColor: CHART_COLORS.ORANGE },
      { text: INCOME_TYPE_LABELS.CASH_INVESTMENT, value: '', pointColor: CHART_COLORS.GREEN },
      { text: INCOME_TYPE_LABELS.TRADE, value: '', pointColor: CHART_COLORS.PURPLE },
      { text: INCOME_TYPE_LABELS.YK, value: '', pointColor: CHART_COLORS.YELLOW },
      { text: INCOME_TYPE_LABELS.MARATHON, value: '', pointColor: CHART_COLORS.BLUE },
      { text: INCOME_TYPE_LABELS.EORDER, value: '', pointColor: CHART_COLORS.RED },
    ]);

    const barChartData = reactive({
      labels: ['O', 'Ş', 'M', 'N', 'M', 'H', 'T', 'A', 'E', 'E', 'K', 'A'],
      datasets: [
        {
          label: INCOME_TYPE_LABELS.SALE,
          backgroundColor: CHART_COLORS.ORANGE,
          data: [],
          borderSkipped: false,
          barThickness: 12,
          hidden: false,
          display: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: INCOME_TYPE_LABELS.CASH_INVESTMENT,
          backgroundColor: CHART_COLORS.GREEN,
          hidden: false,
          data: [],
          barThickness: 12,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: INCOME_TYPE_LABELS.TRADE,
          backgroundColor: CHART_COLORS.PURPLE,
          hidden: false,
          data: [],
          barThickness: 12,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: INCOME_TYPE_LABELS.YK,
          backgroundColor: CHART_COLORS.YELLOW,
          hidden: false,
          data: [],
          barThickness: 12,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: INCOME_TYPE_LABELS.MARATHON,
          backgroundColor: CHART_COLORS.BLUE,
          hidden: false,
          data: [],
          barThickness: 12,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: INCOME_TYPE_LABELS.EORDER,
          backgroundColor: CHART_COLORS.RED,
          hidden: false,
          data: [],
          barThickness: 12,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
      ],
    });

    const barChartOptions = {
      type: 'bar',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: tooltipItems => {
              const index = tooltipItems[0].dataIndex;
              return getMonthFullNameByIndex(index);
            },
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 5,
            color: context => {
              if (context.index === +selectedItemIndex.value) {
                return CHART_COLORS.ORANGE;
              } else {
                return CHART_COLORS.GRAY;
              }
            },
            font: context => {
              const baseFont = {
                size: 12,
                weight: '400',
              };

              if (context.index === +selectedItemIndex.value) {
                return {
                  ...baseFont,
                  size: 14,
                  weight: '500',
                };
              } else {
                return baseFont;
              }
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 5,
            color: CHART_COLORS.DARK_GRAY,
            font: {
              size: 12,
              weight: '400',
            },
          },
        },
      },
    };

    const dateFilterItems = ref([
      { id: 0, date: new Date().getFullYear() - 1, isActive: false, isPrev: true },
      { id: 1, date: new Date().getFullYear(), isActive: true, isPrev: false },
    ]);

    const toggleDateFilter = item => {
      dateFilterItems.value?.forEach(filter => {
        filter.isActive = false;
      });

      item.isActive = true;

      activeYear.value = item.date;

      setDetailYear();

      getIncomeTracking();
    };

    const getMonthFullNameByIndex = index =>
      [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ][index];

    const selectedBarItem = item => {
      if (!item) return;

      selectedItemIndex.value = item.index;
      selectedMonthLabel.value = barChartData.labels[item.index];
      selectedMonthFullName.value = getMonthFullNameByIndex(item.index);

      setDetailYear();
      getIncomeTracking();
    };

    const fillMissingMonths = data => {
      const currentYear = new Date().getFullYear();
      const latestDate = data.reduce((max, { date }) => (date > max ? date : max), '000000');
      const [latestYear, latestMonth] = [
        parseInt(latestDate.slice(0, 4), 10),
        parseInt(latestDate.slice(4, 6), 10),
      ];

      for (let year = latestYear; year <= currentYear; year++) {
        for (let month = year === latestYear ? latestMonth + 1 : 1; month <= 12; month++) {
          const monthString = `${year}${month.toString().padStart(2, '0')}`;
          if (!data.some(({ date }) => date === monthString)) {
            data.push({
              date: monthString,
              monthlyTotalIncome: 0,
              incomes: [
                { type: INCOME_TYPE_LABELS.CASH_INVESTMENT, totalIncome: 0 },
                { type: INCOME_TYPE_LABELS.TRADE, totalIncome: 0 },
                { type: INCOME_TYPE_LABELS.YK, totalIncome: 0 },
                { type: INCOME_TYPE_LABELS.MARATHON, totalIncome: 0 },
                { type: INCOME_TYPE_LABELS.EORDER, totalIncome: 0 },
                { type: INCOME_TYPE_LABELS.SALE, totalIncome: 0 },
              ],
            });
          }
        }
      }
    };

    const getIncomeTracking = async () => {
      const { data } = await BusinessDashboard.getIncomeTracking();

      if (!data.Data) return;

      monthlyIncomes.value = data.Data.monthlyIncomes;
      posChannelType.value = data.Data.posChannelType;

      if (monthlyIncomes.value) {
        fillMissingMonths(monthlyIncomes.value);
      }

      filterTypes.value = filterTypes.value.filter(type => {
        if (
          posChannelType.value === POS_CHANNEL_TYPES.LAMP &&
          type.type === FILTER_TYPES.CASH_INVESTMENT
        ) {
          return false;
        }
        return true;
      });

      updateBarChartData();

      monthlyIncomeDetail.value =
        monthlyIncomes?.value?.filter(f => f.date == fullMonthYear.value)[0] ?? {};

      const isCurYear = activeYear.value == new Date().getFullYear();

      const currentFilterData = isCurYear ? data.Data.curYear : data.Data.prevYear;

      yearlyTotalIncome.value = currentFilterData.yearlyTotalIncome;

      filterTypes.value?.forEach(filter => {
        if (currentFilterData.hasOwnProperty(filter.type)) {
          filter.value =
            currentFilterData[filter.type] === 0 ? '0' : currentFilterData[filter.type];
        }
      });

      montlyDetails.value?.forEach(item1 => {
        let matchingItem = monthlyIncomeDetail.value.incomes?.find(
          item2 => item2.type === item1.text,
        );
        if (matchingItem) {
          item1.value = matchingItem.totalIncome;
        }
      });

      const filteredData = monthlyIncomes.value?.filter(month =>
        month.date.startsWith(activeYear.value),
      );

      barChartData.datasets?.forEach(dataset => {
        dataset.data = [];
      });

      filteredData?.forEach(month => {
        barChartData.datasets?.forEach(dataset => {
          const income = month.incomes?.find(income => income.type === dataset.label);
          dataset.data.push(income ? income.totalIncome : 0);
        });
      });
    };

    const setDetailYear = () => {
      const selectedYear = dateFilterItems.value?.find(f => f.isActive).date;
      if (!selectedYear) return;

      activeYear.value = selectedYear;

      const selectedMonth = +selectedItemIndex.value + 1;

      fullMonthYear.value = `${activeYear.value}${
        selectedMonth < 10 ? '0' + selectedMonth : selectedMonth
      }`;
    };

    const updateBarChartData = () => {
      if (!barChartData?.datasets || !filterTypes.value) return;

      barChartData.datasets.forEach(dataset => {
        const filter = filterTypes.value.find(f => f.text === dataset.label);
        if (filter) {
          dataset.hidden = !filter.isActive;
        }
      });
    };

    const toggleFilter = item => {
      item.isActive = !item.isActive;

      updateBarChartData();
    };

    const monthlyTotalIncome = computed(() => {
      return monthlyIncomeDetail.value.monthlyTotalIncome ?? '0';
    });

    const getMonthDetailValue = month => {
      return (
        formatNumberWithComma(month.value) + (month.text !== 'Aylık Puanlama' ? ' TL' : ' Puan')
      );
    };

    onBeforeMount(() => {
      const currentDate = new Date();
      let currentMonth = currentDate.getMonth();
      selectedBarItem({ index: currentMonth });
    });

    return {
      activeYear,
      filterTypes,
      toggleFilter,
      barChartData,
      montlyDetails,
      setDetailYear,
      barChartOptions,
      toggleDateFilter,
      dateFilterItems,
      selectedBarItem,
      getIncomeTracking,
      yearlyTotalIncome,
      monthlyTotalIncome,
      updateBarChartData,
      formatNumberWithComma,
      selectedMonthLabel,
      getMonthDetailValue,
      selectedItemIndex,
      selectedMonthFullName,
      CHART_COLORS,
    };
  },
};
</script>
<style scoped lang="scss">
.tracking-earnings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 30px;

  .tracker-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .annual-earnings-summary,
    .montly-earnings-summary {
      height: 105px;
      display: flex;
      border-radius: 8px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #f8f9f9;
      border: 1px solid #ededed;

      .summary-title {
        color: #79838e;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .summary-summary {
        color: #23303d;
        font-size: 26px;
        font-weight: 800;
      }
    }

    .filters-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
    }

    .filters-item {
      height: auto;
      width: 49%;
      border-radius: 8px;
      border: 1px solid #ededed;
      background: #f8f9f9;
      padding: 10px;
      display: flex;
      margin-bottom: 10px;
      justify-content: space-between;
      flex-direction: column;

      .filter-details {
        display: flex;
        align-items: center;

        .filter-type {
          color: #79838e;
          font-size: 10px;
          font-weight: 400;
          padding-left: 4px;
        }

        .filter-point {
          width: 6px;
          height: 6px;
          border-radius: 100%;
        }
      }

      .filter-value {
        color: #23303d;
        font-size: 12px;
        font-weight: 800;
        padding-top: 10px;
      }

      .filter-toggle {
        margin-left: auto;
        .switch {
          position: relative;
          display: inline-block;
          width: 34px;
          height: 20px;
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: 0.4s;
          border-radius: 34px;
        }

        .slider:before {
          position: absolute;
          content: '';
          height: 14px;
          width: 14px;
          border-radius: 50%;
          left: 3px;
          bottom: 3px;
          background-color: white;
          transition: 0.4s;
        }

        input:checked + .slider {
          background: linear-gradient(rgba(210, 0, 81, 1), rgba(255, 163, 0, 1));
        }

        input:checked + .slider:before {
          transform: translateX(14px);
        }
      }

      @media (max-width: 768px) {
        .filters-item {
          flex-basis: calc(100% - 20px);
        }
      }
    }

    .montly-earning-details {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid #ededed;
      background: #f8f9f9;
      padding: 20px;
      margin-top: 20px;

      .detail-item {
        display: flex;
        flex-direction: column;

        .detail-content {
          display: flex;
          align-items: center;
          padding: 10px 0;
          justify-content: space-between;

          .filter-point {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 8px;
          }

          .detail-type {
            color: #79838e;
            font-size: 14px;
            font-weight: 400;
          }

          .detail-value {
            color: #23303d;
            font-size: 14px;
            font-weight: 800;
          }
        }

        .divider {
          height: 1px;
          background-color: #ededed;
          width: 100%;
          margin: 5px 0;
        }
      }
    }
  }

  .chart-divider {
    width: 100%;
    height: 1px;
    background-color: #ededed;
  }

  .monthly-detail-title {
    margin: 30px 0 30px 10px;
    color: #23303d;
    font-size: 18px;
    font-weight: 700;
  }

  .point-detail {
    margin-right: palette-space-level(10);
    margin-top: palette-space-level(15);
  }

  .chart-date-filter {
    display: flex;
    align-items: flex-start;
    margin: 16px 0;
  }

  .chart-date-filter + div {
    display: flex;
    justify-content: flex-end;
  }

  .chart-date-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
  }
  .chart-date-text {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
  }
}
</style>
